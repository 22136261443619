import { useEffect, useState } from 'react';

import { NavLink, useSubmit } from '@remix-run/react';

import { withZod } from '@remix-validated-form/with-zod';
import { z } from 'zod';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';

import type { ActionData } from './_index';

import LoginHero from '~/components/LoginHero';

import raffleLogo from '/public/assets/raffle-dark-purple.svg';

import {
  Button,
  DiffableForm,
  HiddenInput,
  Text,
  ValidatedField,
  ValidatedInput,
} from '~/components';

const validator = withZod(
  z.object({
    email: z
      .string()
      .min(1, { message: 'Please provide your email' })
      .email({ message: 'E-mail address not found. Try again' }),
    password: z.string(),
  }),
);

type Props = {
  actionData?: ActionData['validationErrors'];
};

const LoginView = ({ actionData }: Props) => {
  // const fetcher = useFetcher();
  const submit = useSubmit();
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');

  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'development') return;

  //   const emailRegex =
  //     /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  //   if (email.match(emailRegex)) {
  //     fetcher.submit(
  //       { email },
  //       {
  //         method: 'post',
  //         action: '/auth/google',
  //       },
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [email]);

  useEffect(() => {
    if (actionData?.fieldErrors) {
      setStep(1);
    }
  }, [actionData]);

  return (
    <>
      <div className="hidden h-full w-full overflow-hidden md:flex">
        <div className="align-center z-50 flex h-full w-1/4 min-w-[300px] flex-col justify-between bg-neutral-100 px-8 pb-6 pt-48">
          <div className="flex flex-col items-center justify-center">
            <Text size="lg" weight="bold" className="mb-12 !text-neutral-600">
              Welcome back
            </Text>

            <DiffableForm
              id="login"
              validator={validator}
              method="post"
              className="flex w-full flex-col gap-4"
              CustomSubmit={({ disabled }: { disabled: boolean }) => (
                <>
                  {step === 2 ? (
                    <div className="mt-auto flex w-full flex-row justify-between gap-8">
                      <Button
                        type="submit"
                        variant="primary"
                        className="w-full"
                        disabled={disabled}
                      >
                        Sign in{' '}
                        <ArrowRightOnRectangleIcon className="w-5 text-white" />
                      </Button>
                    </div>
                  ) : (
                    <div className="mt-auto flex w-full flex-row justify-between gap-8">
                      <Button
                        type="button"
                        variant="primary"
                        className="w-full"
                        onClick={() => setStep(2)}
                        disabled={email.length === 0}
                      >
                        Next
                      </Button>
                    </div>
                  )}
                </>
              )}
            >
              {step === 1 ? (
                <ValidatedField
                  name="email"
                  label="Business e-mail"
                  className="w-full"
                  onKeyDown={(e) => e.key === 'Enter' && setStep(2)}
                  onChange={(e) => setEmail(e.target.value)}
                >
                  <ValidatedInput
                    placeholder="Enter your business e-mail here..."
                    className="w-full"
                    autoComplete="email"
                    defaultValue={actionData?.repopulateFields.email}
                  />
                </ValidatedField>
              ) : (
                <>
                  <HiddenInput name="email" value={email} />
                  <ValidatedField
                    name="password"
                    label="Password"
                    className="w-full"
                    type={showPassword ? 'text' : 'password'}
                    onKeyDown={(e) =>
                      e.key === 'Enter' && submit(e.currentTarget.form)
                    }
                  >
                    <ValidatedInput
                      placeholder="Enter your password..."
                      RightIcon={showPassword ? EyeSlashIcon : EyeIcon}
                      rightIconClickHandler={() =>
                        setShowPassword(!showPassword)
                      }
                      autoComplete="current-password"
                      defaultValue={actionData?.repopulateFields.password}
                    />
                  </ValidatedField>

                  <div className="flex justify-end">
                    <NavLink to="/reset">
                      <Text
                        size="sm"
                        weight="medium"
                        className="text-accent-purple-400"
                      >
                        I forgot my password
                      </Text>
                    </NavLink>
                  </div>
                </>
              )}
            </DiffableForm>

            <Text className="mt-6">
              New to Raffle?{' '}
              <a href="/signup" className="text-accent-purple-400">
                Try Raffle for free
              </a>
            </Text>
          </div>
          <span className="mx-auto mt-auto text-neutral-500">
            &copy; raffle.ai
          </span>
        </div>

        <LoginHero />
      </div>

      <div className="flex h-full w-full flex-col justify-center gap-4 p-12 md:hidden">
        <img src={raffleLogo} alt="raffle-logo" className="w-[80px]" />

        <div className="flex flex-col gap-4">
          <>
            <Text
              size="xl"
              weight="medium"
              className="!leading-[115%] tracking-tight !text-neutral-600"
            >
              To use our app please login using a desktop device
            </Text>
            <Text>
              Are you having issues?{' '}
              <a
                href="mailto:support@raffle.ai"
                className="text-accent-purple-400"
              >
                Contact support
              </a>
            </Text>
          </>
        </div>
      </div>
    </>
  );
};

export default LoginView;
